import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import TabWorkList from './TabWorkList'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box sx={{ height: '100%' }}>
      {value === index && <Box sx={{ p: 3, height: '87vh' }}>{children}</Box>}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const myTabsProps = [
  {
    labelTab: 'pendingRegistration',
    icon: 'fas fa-user-plus',
    title: '123',
  },
  {
    labelTab: 'registered',
    icon: 'fas fa-user-check',
    title: '123',
  },
  {
    labelTab: 'toQuote',
    icon: 'fas fa-user-tag',
    title: '123',
  },
  {
    labelTab: 'medicalOrderCancelled',
    icon: 'fas fa-user-minus',
    title: '123',
  },
  {
    labelTab: 'toInvoice',
    icon: 'fas fa-file-invoice-dollar',
    title: '123',
  },
]

export default function Worklists() {
  const [value, setValue] = useState(0)
  const intl = useIntl()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Box
        sx={{
          marginTop: '5px',
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="exams-tabs"
          sx={{
            marginLeft: '54px',
            marginBottom: '-22px',
          }}
        >
          {myTabsProps.map((el, index) => {
            return (
              <Tab
                label={intl.formatMessage({ id: el.labelTab })}
                {...a11yProps(0)}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '5px 5px 0px 0px',
                  marginRight: '8px',
                }}
                key={index}
              />
            )
          })}
        </Tabs>
      </Box>

      {myTabsProps.map((el, index) => {
        return (
          <TabPanel value={value} index={index} key={index}>
            <TabWorkList dataTabs={el} />
          </TabPanel>
        )
      })}
    </Box>
  )
}
