import { AccordionDetails, Divider, Grid } from '@mui/material'

import { makeStyles } from '@material-ui/core'
import { RadioInput } from '../../../../components/inputs'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientGeneralnfoSchema } from '@utils/schemas'
import { TextInput } from '../../../../components/inputs'
import { useIntl } from 'react-intl'
type patientInformationProps = {
  updatable: boolean
}

type Values = {
  history: string
}

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(patientGeneralnfoSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} />
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              defaultValue=""
              placeholder="Historique"
              name="history"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              multiline={true}
              fullWidth
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  )
}
