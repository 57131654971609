import { AccordionDetails, Divider, Grid } from '@mui/material'

import { RadioInput } from '../../../../components/inputs'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientGeneralnfoSchema } from '@utils/schemas'
import { TextField } from '@mui/material'

type patientInformationProps = {
  updatable: boolean
}

type Values = {
  inactivityTimeout: number
  twoFAMandatory: boolean
  passwordChangesMandatory: boolean
  passwordChangesPeriod: number
  samePasswordAllowed: boolean
}

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  const { control } = useForm<Values>({
    resolver: yupResolver(patientGeneralnfoSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} />
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            FormPatientExamHistory
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  )
}
