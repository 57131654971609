import { AccordionDetails, Divider, Grid, Checkbox, FormControl, MenuItem, InputLabel, FormHelperText, Select, FormGroup, FormControlLabel, TextField, Radio, RadioGroup, FormLabel  } from '@mui/material'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientGeneralnfoSchema, FeesJournalCodeEnum, relaunchTriggerWhoEnum, relaunchTriggerWhenEnum } from '@utils/schemas'
import { useIntl } from 'react-intl'

type patientInformationProps = {
  updatable: boolean
}

type Values = {
  auxiliaryAccountNumber: string,
  technicalFlatRateJournalCode: string,
  feesJournalCode: string,
  ignoreInRelaunches: boolean,
  ignoreInGeneration: boolean,
  manageClaimForms: boolean,
  manageDisputes: boolean,
  oneInvoicePerPage: boolean,
  relaunchTrigger: string,
  relaunchTriggerDate: string,
  payerOrganization: string,
  comment: string,
}

export default function FormCaisseFinance({
  updatable,
}: patientInformationProps) {
  const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(patientGeneralnfoSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} >P.Financiers</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} spacing={2}>
            <Grid item xs={8}>
              <TextField
                label="Numéro de compte auxiliaire"
                name="auxiliaryAccountNumber"
                size="medium"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          {
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ minWidth: 300 }}>
                <InputLabel id="demo-simple-select-helper-label">Code journal Forfait Technique</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Code journal Forfait Technique"
                  name='technicalFlatRateJournalCode'
                  // value={age}
                  // onChange={}
                >
                  <MenuItem value={FeesJournalCodeEnum.BQCARD}>{FeesJournalCodeEnum.BQCARD}</MenuItem>
                  <MenuItem value={FeesJournalCodeEnum.CA282_H}>{FeesJournalCodeEnum.CA282_H}</MenuItem>
                  <MenuItem value={FeesJournalCodeEnum.SG998_H}>{FeesJournalCodeEnum.SG998_H}</MenuItem>
                </Select>
                <FormHelperText>Helper text for errors</FormHelperText>
              </FormControl>
            </Grid>
          }
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ minWidth: 300 }}>
              <InputLabel id="demo-simple-select-helper-label">Code journal Honoraires</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Code journal Honoraires"
                name='feesJournalCode'
                // value={age}
                // onChange={}
              >
                <MenuItem value={FeesJournalCodeEnum.BQCARD}>{FeesJournalCodeEnum.BQCARD}</MenuItem>
                <MenuItem value={FeesJournalCodeEnum.CA282_H}>{FeesJournalCodeEnum.CA282_H}A</MenuItem>
                <MenuItem value={FeesJournalCodeEnum.SG998_H}>{FeesJournalCodeEnum.SG998_H}</MenuItem>
              </Select>
              <FormHelperText>Helper text for errors</FormHelperText>
            </FormControl>
          </Grid>
          {/* Relance */}
          <Grid item>
            <FormGroup>
              <InputLabel id="demo-simple-select-helper-label">Relances</InputLabel>
              <FormControlLabel name='ignoreInRelaunches' control={<Checkbox />} label="Ignorer dans les traitements des relances" />
              <FormControlLabel name='ignoreInGeneration' control={<Checkbox />} label="Ignorer dans la génération des bordereaux papiers honoraires/FT" />
              <FormControlLabel name='manageClaimForms' control={<Checkbox />} label="Gesttion des formaulaires de réclamations" />
              <FormControlLabel name='manageDisputes' control={<Checkbox />} label="Gestion des contentieux" />
              <FormControlLabel name='oneInvoicePerPage' control={<Checkbox />} label="Une seul facture par page" />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 8 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Déclenchement des relances sur</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="1"
              name="relaunchTriggerBy"
              row
            >
              <FormControlLabel value={relaunchTriggerWhoEnum.BYNAME} control={<Radio />} label="Par les Nom/Prénom de l'assuré" />
              <FormControlLabel value={relaunchTriggerWhoEnum.BYNUM} control={<Radio />} label="Par le numéro de facture" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: 8 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Déclenchement des relances sur</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="1"
              name="relaunchTriggerDate"
              row
            >
              <FormControlLabel value={relaunchTriggerWhenEnum.BYCOME} control={<Radio />} label="Date d'accueil" />
              <FormControlLabel value={relaunchTriggerWhenEnum.BYFICHE} control={<Radio />} label="Date du bordereau" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: 15, marginBottom: 20 }}>
          <TextField
            label="Organisme payeur"
            name="payerOrganization"
            size="medium"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: 'Search',
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Commentaire"
            name="comment"
            size="medium"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
          />
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
    </div>
  )
}
