import { AccordionDetails, Chip, Divider, Grid } from '@mui/material'

import {
  RadioInput,
  SelectInput,
  TextInput,
  CheckBoxInput,
  DateInput,
} from '../../../../components/inputs'
import { makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientGeneralnfoSchema } from '@utils/schemas'
import { useIntl } from 'react-intl'

type patientInformationProps = {
  updatable: boolean
}

type Values = {
  inactivityTimeout: number
  twoFAMandatory: boolean
  passwordChangesMandatory: boolean
  passwordChangesPeriod: number
  samePasswordAllowed: boolean
}

const useStyles = makeStyles(() => ({
  dividerNotTop: { marginBottom: 30, marginTop: 30 },
  chipDivider: {
    backgroundColor: '#e2e6f2',
    fontWeight: 600,
    fontSize: '1.2rem',
  },
}))

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(patientGeneralnfoSchema),
  })

  const classes = useStyles()

  const NatureSocialInsurance = [
    { label: 'Maladie', value: '01' },
    { label: 'Alsace Moselle', value: '02' },
    { label: 'Soins médicaux gratuits', value: '03' },
    { label: 'Maternité', value: '04' },
    { label: 'Accident du Travail', value: '05' },
  ]

  const TypeOfExemption = [
    { label: 'Soins exonération', value: '01' },
    { label: 'Soins relatifs à séjour > 30 j', value: '02' },
    { label: 'Soins particuliers exonérés', value: '03' },
    { label: 'Affections (Liste, ALD, …)', value: '04' },
    { label: 'Régime exonérant', value: '05' },
    { label: 'Régimes spéciaux (Mines)', value: '06' },
    { label: 'Dispositif de prévention', value: '07' },
    { label: 'FSV ou FSI (ancien FNS)', value: '08' },
    { label: 'Soin exonéré CCAM', value: '09' },
  ]

  const ProcessingIndicator = [
    { label: 'Pas de DRE', value: '01' },
    { label: 'TP complémentaire', value: '02' },
    { label: 'TP complémentaire selon réglem', value: '03' },
    { label: 'DRE HTTP pas de rectif', value: '04' },
    { label: 'DRE HTM rectif possible', value: '05' },
    { label: 'DRE TP pas de rectif', value: '06' },
    { label: 'DRE TP rectif possible', value: '07' },
    { label: 'DRE TP HTTP pas de rectif', value: '08' },
    { label: 'DRE TP HTTP rectif possible', value: '09' },
    { label: 'CMU pas DRE pas rectif', value: '10' },
    { label: 'CMU DRE possible pas rectif', value: '11' },
    { label: 'CMU DRE possible rectif possib', value: '12' },
    { label: 'CMU pas éclatement', value: '13' },
    { label: 'CMU-C DRE pas rectif', value: '14' },
    { label: 'CMU-C DRE rectif possible', value: '15' },
  ]

  const RoutingCode = [
    { label: 'Actil', value: '01' },
    { label: 'Almedys', value: '02' },
    { label: 'Assurance maladie', value: '03' },
    { label: 'Carte blanche', value: '04' },
    { label: 'Eovi', value: '05' },
    { label: 'Fédération mutuelle', value: '06' },
    { label: 'GAMEX', value: '07' },
    { label: 'CTIP', value: '08' },
    { label: 'KORELIO', value: '09' },
    { label: 'MSA', value: '10' },
    { label: 'Mutuelle de France', value: '11' },
    { label: 'CCMO', value: '12' },
    { label: 'Mut Santé', value: '13' },
    { label: 'Réseau NOE', value: '14' },
    { label: 'Organisme Complémentaire', value: '15' },
    { label: 'Proval', value: '16' },
    { label: 'Isanté', value: '17' },
    { label: 'Santé Pharma', value: '18' },
    { label: 'Viamedis', value: '19' },
  ]

  const StsSwitchingCode = [
    { label: '4', value: '01' },
    { label: '8', value: '02' },
    { label: 'M', value: '03' },
    { label: 'R', value: '04' },
    { label: 'U', value: '05' },
  ]

  return (
    <div>
      <AccordionDetails>
        <Divider variant="middle" sx={{ marginBottom: 3 }}>
          <Chip
            label="Couvertures"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectInput
              options={NatureSocialInsurance}
              name="NatureSocialInsurance"
              control={control}
              readOnly={!updatable}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              options={TypeOfExemption}
              name="TypeOfExemption"
              control={control}
              readOnly={!updatable}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="S.V.ManagementCode"
              name="S.V.ManagementCode"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="FundForTheAtRegime"
              name="FundForTheAtRegime"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <DateInput name="DateOfWorkAccident" control={control} />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="AccidentAtWorkNumber"
              name="AccidentAtWorkNumber"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <CheckBoxInput
              control={control}
              label="Article 115"
              name="Article 115"
            />
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.dividerNotTop}>
          <Chip
            label="Droit AMO"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="HonoraryFund"
              name="HonoraryFund"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <DateInput name="ValidityOfS.S.Card" control={control} />
          </Grid>

          <Grid item xs={3}>
            <DateInput name="EndOfRights" control={control} />
          </Grid>

          <Grid item xs={3}>
            <DateInput name="100%Validity" control={control} />
          </Grid>

          <Grid item xs={3}>
            <CheckBoxInput
              control={control}
              label="C2SPatient(Cmu)"
              name="C2SPatient(Cmu)"
            />
          </Grid>
          <Grid item xs={3}>
            <CheckBoxInput
              control={control}
              label="AmePatient"
              name="AmePatient"
            />
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.dividerNotTop}>
          <Chip
            label="Droits mutuelle & AMC"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="MutualInsurance"
              name="MutualInsurance"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="MemberNumber"
              name="MemberNumber"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <DateInput name="ExpiryDate" control={control} />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="MutualInsuranceContract"
              name="MutualInsuranceContract"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.dividerNotTop}>
          <Chip
            label="DRE"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectInput
              options={ProcessingIndicator}
              name="ProcessingIndicator"
              control={control}
              readOnly={!updatable}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              options={RoutingCode}
              name="RoutingCode"
              control={control}
              readOnly={!updatable}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="DomainName"
              name="DomainName"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="TypeOfAgreement"
              name="TypeOfAgreement"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="RegulatingOperator"
              name="RegulatingOperator"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="PricingCode"
              name="PricingCode"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              options={StsSwitchingCode}
              name="StsSwitchingCode"
              control={control}
              readOnly={!updatable}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="HostId"
              name="HostId"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="SecondaryCriteria"
              name="SecondaryCriteria"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  )
}
