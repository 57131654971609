import { Button } from '@components/buttons'
import { Text } from '@components/texts'
import { DialogTitle } from '@components/titles'
import { DataTable } from '@components/tables'
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  Paper,
  Popover,
  Typography,
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import {
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { Actions } from '@state/actions'
import { useSelector } from '@state/store'
import { deleteType } from '@state/thunks/procedureThunk'
import theme from '@utils/theme'
import { MouseEvent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'
import PatientDialog from '../Worklists/PatientDialog'

const useStyles = makeStyles(() => ({
  action: {
    cursor: 'pointer',
    padding: 6,
    margin: '2px 0px',
    '&:hover': {
      backgroundColor: 'rgb(236,240,251)',
    },
  },
}))

export interface DialogProps {
  openPatientDetails: boolean
}

export default function TabBilling (props) {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPatientDialog, setOpenPatientDialog] = useState(false)
  const [updatePatientDialog, setUpdatePatientDialog] = useState(false)
  const [test, setTest] = useState(false)

  const classes = useStyles()

  moment.locale('fr')

  const { loading } = useSelector(({ loading }) => ({
    loading:
      loading[Actions.PROCEDURE_TYPE_FIND_ALL] ||
      loading[Actions.PROCEDURE_TYPE_CREATE] ||
      loading[Actions.PROCEDURE_TYPE_UPDATE],
  }))

  const actionBtnObj = [
    {
      id: 1,
      btnName: 'Accueillir le patient',
      icon: 'fas fa-door-open',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionPatientWelcome(),
    },
    {
      id: 2,
      btnName: 'Accueillir le patient avec Carte Vitale',
      icon: 'fas fa-id-card',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionPatientWelcomeWithCard(),
    },
    {
      id: 3,
      btnName: 'Consulter la demande',
      icon: 'fas fa-eye',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionLookAtMO(),
    },
    {
      id: 4,
      btnName: 'Modifier la demande',
      icon: 'fas fa-edit',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionUpdateMO(),
    },
    {
      id: 5,
      btnName: 'Fiche patient',
      icon: 'fas fa-user',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionPatientDetails(),
    },
    {
      id: 6,
      btnName: 'Fusionner le patient',
      icon: 'fas fa-users-cog',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionPatientMerge(),
    },
  ]

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickActionPatientWelcome = () => {
    console.log('Acceuillir le patient')
    handleClose()
  }
  const handleClickActionPatientWelcomeWithCard = () => {
    console.log('acceuillir le patient avec Carte vitale')
    handleClose()
  }
  const handleClickActionLookAtMO = () => {
    console.log('Consulter la demande')
    handleClose()
  }
  const handleClickActionUpdateMO = () => {
    handleClose()
    setTest(true)
  }
  const handleClickActionPatientDetails = () => {
    handleClose()
    setOpenPatientDialog(true)
    setUpdatePatientDialog(false)
  }
  const handleClickActionPatientMerge = () => {
    console.log('Fusionner le Patient')
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const columns: GridColumns = [
    {
      field: 'col0',
      headerName: 'Actions',
      width: 80,
      sortable: false,
      renderCell: (value) => (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Icon
            className="fas fa-align-justify"
            onClick={handleClick}
            sx={{ color: '#3F3F3F' }}
          />
          <Popover
            elevation={2}
            PaperProps={{ style: { padding: 10, borderRadius: '15px' } }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {actionBtnObj.map((el, i) => {
              return (
                <Typography
                  key={i}
                  className={classes.action}
                  onClick={el.action}
                >
                  <Icon
                    className={el.icon}
                    sx={{ paddingRight: 2, color: '#3F3F3F' }}
                  />
                  {el.btnName}
                </Typography>
              )
            })}
          </Popover>
        </div>
      ),
    },

    {
      field: 'col1',
      headerName: 'Date',
      flex: 1,
      description: 'ceci est un test de description',
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.plannedDate).format('L'),
    },

    {
      field: 'col2',
      headerName: 'Heure',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.plannedDate).format('LT'),
    },
    {
      field: 'col3',
      headerName: 'Nom - Prénom',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.lastName} - ${params.row.firstName}`,
    },
    {
      field: 'col4',
      headerName: 'Date de naissance',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.birthdayDate).format('L'),
    },
    {
      field: 'col5',
      headerName: 'Age',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        moment().diff(params.row.birthdayDate, 'years'),
    },
    {
      field: 'col6',
      headerName: 'Poids',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.weight,
    },
    {
      field: 'col7',
      headerName: 'Libéllé Protocole d’examen',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.protocol,
    },
    {
      field: 'col8',
      headerName: 'type de modalité',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.modalityType,
    },
    {
      field: 'col9',
      headerName: 'IPP',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.IPP,
    },
    {
      field: 'col10',
      headerName: 'N° examen',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.examId,
    },
    {
      field: 'col11',
      headerName: 'N° séjour',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.visitId,
    },
    {
      field: 'col12',
      headerName: 'Statut patient',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.statusPatient,
    },
    {
      field: 'col13',
      headerName: 'Examen(s) réalisé(s)',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.medicalOrderDone,
    },
    {
      field: 'col14',
      headerName: 'Examen(s) confirmé(s)',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.medicalOrderConfirmed,
    },
    {
      field: 'col15',
      headerName: 'Ordonnace',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.prescription,
    },
    {
      field: 'col16',
      headerName: 'Salle examen',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.reviewRoom,
    },
    {
      field: 'col17',
      headerName: 'Salle traitement',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.processingRoom,
    },
  ]

  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteType(selectedRows))
    setIsDeleteDialogOpen(false)
  }
  const medicalOrderMockup = [
    {
      id: 1,
      examId: '999',
      plannedDate: new Date(),
      idPatient: 1,
      firstName: 'Jean',
      lastName: 'Dupond',
      birthdayDate: '1992-10-18T00:00:00.000Z',
      weight: '80',
      height: '175',
      protocol: 'ABCXXX',
      modalityType: 'XXX',
      IPP: '000',
      visitId: '123',
      statusPatient: 'Sleeping',
      medicalOrderDone: ['radio du pied', 'IRM', 'consultation'],
      medicalOrderConfirmed: ['radio du pied', 'IRM', 'consultation'],
      prescription: 'XXX',
      reviewRoom: '5',
      processingRoom: '2',
    },
    {
      id: 2,
      examId: '999',
      plannedDate: new Date(),
      idPatient: 1,
      firstName: 'Jean',
      lastName: 'Dupond',
      birthdayDate: '1992-10-18T00:00:00.000Z',
      weight: '80',
      height: '175',
      protocol: 'ABCXXX',
      modalityType: 'XXX',
      IPP: '000',
      visitId: '123',
      statusPatient: 'Sleeping',
      medicalOrderDone: ['radio du pied', 'IRM', 'consultation'],
      medicalOrderConfirmed: ['radio du pied', 'IRM', 'consultation'],
      prescription: 'XXX',
      reviewRoom: '5',
      processingRoom: '2',
    },
    {
      id: 3,
      examId: '999',
      plannedDate: new Date(),
      idPatient: 1,
      firstName: 'Jean',
      lastName: 'Dupond',
      birthdayDate: '1992-10-18T00:00:00.000Z',
      weight: '80',
      height: '175',
      protocol: 'ABCXXX',
      modalityType: 'XXX',
      IPP: '000',
      visitId: '123',
      statusPatient: 'Sleeping',
      medicalOrderDone: ['radio du pied', 'IRM', 'consultation'],
      medicalOrderConfirmed: ['radio du pied', 'IRM', 'consultation'],
      prescription: 'XXX',
      reviewRoom: '5',
      processingRoom: '2',
    },
  ]

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 0px' }}>
      <Grid container spacing={4} style={{ height: '96vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{
              width: '100%',
              borderRadius: '0px 25px 25px 25px',
            }}
          >
            <DataTable
              rows={medicalOrderMockup}
              columns={columns}
              loading={loading}
              size={30}
              toolBarProps={{
                title: props.dataTabs.labelTab,
                icon: props.dataTabs.icon,
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('veuillez sélectionner au moins une donnée')
                },
              }}
              detailPanelHeight={400}
              form={(props) => <></>}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>
      <PatientDialog
        patientId={5}
        open={openPatientDialog}
        setOpen={setOpenPatientDialog}
      />
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteReception"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            // onClick={_onDeleteConfirm}
            color="primary"
            type="submit"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}
