import { AccordionDetails, Divider, Grid, Checkbox, FormControlLabel, InputLabel,FormControl, Select,MenuItem, FormHelperText, RadioGroup, FormLabel, Radio, TextField } from '@mui/material'
import * as Yup from 'yup'
import {
  DateInput,
} from '../../../../components/inputs'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { mutuelleGeneralInfoSchema, TypeMutuelleEnum, ThirdPartyPaymentEnum, contactStatusEnum } from '@utils/schemas'
import { useIntl } from 'react-intl'

type patientInformationProps = {
  updatable: boolean
}

type Values = Yup.InferType<typeof mutuelleGeneralInfoSchema>

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  // const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(mutuelleGeneralInfoSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} >Géneral</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <TextField
              label="Code organisme"
              name="organizationCode"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} lg={5}>
            <TextField
              label="Nom de l'organisme"
              name="organizationName"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item sx={{ textAlign: 'center' }} xs={3}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="AMC"
                name="type"
                row
              >
                <FormControlLabel value={TypeMutuelleEnum.MUTUELLE} control={<Radio />} label="Mutuelle" />
                <FormControlLabel value={TypeMutuelleEnum.AMC}  control={<Radio />} label="AMC" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Taux de remboursement"
              name="refundRate"
              size="medium"
              variant="outlined"
              InputProps={{
                endAdornment: '%',
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              name="lastBordereau"
              label="Dernière bordereau"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          {/* section 0 */}
          <Grid item sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Tiers-payant</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Tiers-payant"
                name='thirdPartyPayment'
                // value={}
                // onChange={}
              >
                <MenuItem value={ThirdPartyPaymentEnum.TP}>Tiers-payant (mutuel)</MenuItem>
                <MenuItem value={ThirdPartyPaymentEnum.HE}>100% Honoraires (org.extérieur)</MenuItem>
                <MenuItem value={ThirdPartyPaymentEnum.HEFT}>100% Honoraires + FT (org.extérieur)</MenuItem>
              </Select>
              <FormHelperText>Helper text for errors</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel control={<Checkbox />} label="Prise en change totale des dépassements d'honoraires" />
            <FormControlLabel control={<Checkbox />} label="Prise en charge de la pénalité assuré (PAV)" />
          </Grid>
          {/* section 1 */}
          <Divider variant="fullWidth" sx={{ marginBottom: 3 }} />
          <Grid item xs={12} lg={6}>
            <TextField
              label="Adresse"
              name="adresse"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Code postal"
              name="postalCode"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Ville"
              name="city"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* section 2 */}
          <Grid item xs={12} lg={3}>
            <TextField
              label="Nom du contact"
              name="contactName"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Status du contact</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="contactStatus"
                name='contactStatus'
                // value={age}
                // onChange={}
              >
                <MenuItem value={contactStatusEnum.DR}>Docteur</MenuItem>
                <MenuItem value={contactStatusEnum.DRE}>Docteure</MenuItem>
                <MenuItem value={contactStatusEnum.MR}>Monsieur</MenuItem>
                <MenuItem value={contactStatusEnum.MME}>Madame</MenuItem>
                <MenuItem value={contactStatusEnum.ENF}>Enfant</MenuItem>
              </Select>
              <FormHelperText>Helper text for errors</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Adreesse électronique"
              name="email"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Tél. professionnel"
              name="professionalPhone"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Fax"
              name="fax"
              size="medium"
              variant="outlined"
              fullWidth
            />
            
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginBottom: 3, marginTop: 3 }} >Télétransmission</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Identifiant mutuelle"
              name="mutuelleId"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel name='toTransmit' control={<Checkbox />} label="A transmettre" />
          </Grid>
          {/* <Divider /> */}
          <Grid item xs={4}>
            <Grid item xs={12}>
              <DateInput
                name="teletransmissionDate"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <DateInput
                name="activeFrom"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <DateInput
                name="activeTo"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
    </div>
  )
}
