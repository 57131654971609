import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import Text from '@components/Text'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import FormPatientRightsBill from './Form/FormPatientRightsBill'
import FormPatientRightsSocialSecurity from './Form/FormPatientRightsSocialSecurity'

type dialogPatientInfosProps = {
  updatable: boolean
}

const useStyles = makeStyles(() => ({
  accordion: { width: '100%' },
  accordionSummary: {
    cursor: 'pointer',
    paddingLeft: '25px',
    height: '90px',
    '&:hover': {
      backgroundColor: 'rgb(236,240,251)',
    },
  },
  typographyAccordionSummary: {},
}))

export default function DialogPatientInfos({
  updatable,
}: dialogPatientInfosProps) {
  const classes = useStyles()
  return (
    <Grid container spacing={0.5} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Accordion className={classes.accordion} square={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Text text="rightRefund" variant="h6" format />
          </AccordionSummary>
          <FormPatientRightsBill updatable={updatable} />
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion className={classes.accordion} square={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Text text="insuredInfo" variant="h6" format />
          </AccordionSummary>
          <FormPatientRightsSocialSecurity updatable={updatable} />
        </Accordion>
      </Grid>
    </Grid>
  )
}
