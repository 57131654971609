import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import FormPatientInfoGeneral from './Form/FormPatientInfoGeneral'
import FormPatientHistory from './Form/FormPatientInfoHistory'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormPatientSocialSecurity from './Form/FormPatientInfoSocialSecurity'
import { Button } from '@components/buttons/Button'

const useStyles = makeStyles(() => ({
  // accordion: { width: '100%' },
  // accordionSummary: {
  //   cursor: 'pointer',
  //   paddingLeft: '25px',
  //   height: '90px',
  //   '&:hover': {
  //     backgroundColor: 'rgb(236,240,251)',
  //   },
  // },
  typographyAccordionSummary: { width: '100%', backgroundColor: 'red' },
}))

export default function DialogPatientInfos() {
  const classes = useStyles()

  const handleClickPotailButton = () => {
    window.open('https://portal.ether.healthcare/')
  }

  return (
    <Grid container spacing={0.5} justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ marginTop: 3, textAlign: 'center' }}>
        <Button
          text="Portail client"
          variant="contained"
          textColor="white"
          onClick={handleClickPotailButton}
        />
      </Grid>
    </Grid>
  )
}
