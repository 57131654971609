import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import FormPatientInfoGeneral from './Form/FormPatientInfoGeneral'
import FormPatientHistory from './Form/FormPatientInfoHistory'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormPatientSocialSecurity from './Form/FormPatientInfoSocialSecurity'

import FormPatientValidationDMP from './Form/FormPatientValidationDMP'

type dialogPatientInfosProps = {
  updatable: boolean
}

const useStyles = makeStyles(() => ({
  accordion: { width: '100%' },
  accordionSummary: {
    cursor: 'pointer',
    paddingLeft: '25px',
    height: '90px',
    '&:hover': {
      backgroundColor: 'rgb(236,240,251)',
    },
  },
  typographyAccordionSummary: {},
}))

export default function DialogPatientInfos({
  updatable,
}: dialogPatientInfosProps) {
  const classes = useStyles()
  return (
    <Grid container spacing={0.5} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Accordion className={classes.accordion} square={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Typography
              className={classes.typographyAccordionSummary}
              variant="h6"
            >
              DMP
            </Typography>
          </AccordionSummary>
          <FormPatientValidationDMP updatable={updatable} />
        </Accordion>
      </Grid>
    </Grid>
  )
}
