import SplashScreen from '../billing/SplashScreen'
import { useSelector } from '@state/store'
import { AccountRoleEnum } from '@services/api'

export const memberLinks = [
  {
    to: '/m/',
    title: 'Home',
    icon: 'fa fa-home',
  },
  {
    to: '/m/worklist',
    title: 'Work list',
    icon: 'far fa-list-alt',
  },
  {
    to: '/m/sites',
    title: 'Sites',
    icon: 'fa fa-hospital',
  },
  {
    to: '/m/rooms',
    title: 'Rooms',
    icon: 'fab fa-uikit',
  },
  {
    to: '/m/members',
    title: 'Member',
    icon: 'fa fa-user',
  },
  {
    to: '/m/roles',
    title: 'Roles',
    icon: 'fas fa-user-lock',
  },
  {
    to: '/m/procedures',
    title: 'Procedures',
    icon: 'fa fa-syringe',
  },
  {
    to: '/m/questions',
    title: 'Questions',
    icon: 'fab fa-wpforms',
  },
  {
    to: '/m/equipment',
    title: 'Technical platform',
    icon: 'fas fa-tools',
  },
  {
    to: '/m/stats',
    title: 'Stats',
    icon: 'fas fa-chart-line',
  },
  {
    to: '/m/billing',
    title: 'Billing',
    icon: 'fa fa-file-invoice-dollar',
  },
  {
    to: '/m/insi',
    title: 'Insi',
    icon: 'fa fa-stethoscope',
  },
  // {
  //   to: '/m/logs',
  //   title: 'Logs',
  //   icon: 'fa fa-database',
  // },
  {
    to: '/ambulances',
    title: 'Ambulances',
    icon: 'fa fa-ambulance',
  },
  {
    to: '/m/preferences',
    title: 'Preferences',
    icon: 'fas fa-cogs',
  },
]

export default function HomeMember() {
  const role = useSelector(({ auth }) => auth.account?.role)
  return (
    role === AccountRoleEnum.guest ? <SplashScreen /> :
      role === AccountRoleEnum.member ? <SplashScreen /> :
        role === AccountRoleEnum.patient ? <SplashScreen /> :
          role === AccountRoleEnum['super-admin'] ? <SplashScreen /> :
            role === AccountRoleEnum.doctor ? <SplashScreen /> : <><div>Connectez-vous au service</div></>
  )
}
