import {
  IRequestConfig,
  MedicalOrder,
  PaginatedQuery,
  UpdateMedicalOrderDto,
  axios,
  getConfigs,
} from './api'
import queryString from 'querystring'
import { PaginatedDto } from './extendedType'

export interface FindMedicalOrdersQuery extends PaginatedQuery {
  accessionNumber?: string
  clientId?: string
  includeTransport?: boolean
}

export class MedicalOrderService {
  private static readonly endpoint = 'medical-order'

  static get(
    query: FindMedicalOrdersQuery,
  ): Promise<PaginatedDto<MedicalOrder>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint + '?' + queryString.stringify({ ...query }),
        {},
      )

      axios(configs, resolve, reject)
    })
  }
  static update(id: number, dto: UpdateMedicalOrderDto): Promise<MedicalOrder> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        this.endpoint + '/' + id,
        dto,
      )

      axios(configs, resolve, reject)
    })
  }
}
