import { AccordionDetails, Chip, Divider, Grid, Paper } from '@mui/material'

import {
  RadioInput,
  SelectInput,
  TextInput,
  CheckBoxInput,
  DateInput,
} from '../../../../components/inputs'
import Text from '@components/Text'
import { DataTable, SimpleTable } from '@components/tables'
import {
  DataGridPro,
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientGeneralnfoSchema } from '@utils/schemas'
import { TextField } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ChipCell } from '@components/tables/cells'
import { useSelector } from '@state/store'
import { Actions } from '@state/actions'

type patientInformationProps = {
  updatable: boolean
}

type Values = {
  inactivityTimeout: number
  twoFAMandatory: boolean
  passwordChangesMandatory: boolean
  passwordChangesPeriod: number
  samePasswordAllowed: boolean
}

const useStyles = makeStyles(() => ({
  dividerNotTop: { marginBottom: 30, marginTop: 30 },
  chipDivider: {
    backgroundColor: '#e2e6f2',
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  textName: {},
  textAnswer: { fontWeight: 'bold', marginLeft: '10px' },
}))

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(patientGeneralnfoSchema),
  })

  const dispatch = useDispatch()

  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])
  const classes = useStyles()
  const prefix = [
    { label: 'Docteur', value: '01' },
    { label: 'Enfant', value: '02' },
    { label: 'Madame', value: '03' },
    { label: 'Monsieur', value: '04' },
  ]

  const columns: GridColumns = [
    {
      field: 'lastName',
      headerName: 'Nom',
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: 'Prénom',
      flex: 1,
    },
    {
      field: 'entitled',
      headerName: 'Ayant-droit',
      flex: 1,
    },
    {
      field: 'sex',
      headerName: 'Sexe',
      flex: 1,
    },
    {
      field: 'birthDay',
      headerName: 'Date naissance',
      flex: 1,
    },
  ]

  const beneficiaryMockUp = [
    {
      id: 1,
      lastName: 'Zoli',
      firstName: 'Johan',
      entitled: 'Assuré',
      sex: 'homme',
      birthDay: '01/01/2000',
    },
    {
      id: 2,
      lastName: 'Zoli',
      firstName: 'Johan',
      entitled: 'Enfant',
      sex: 'femme',
      birthDay: '01/01/2000',
    },
    {
      id: 3,
      lastName: 'Zoli',
      firstName: 'Johan',
      entitled: 'Enfant',
      sex: 'femme',
      birthDay: '01/01/2000',
    },
    {
      id: 4,
      lastName: 'Zoli',
      firstName: 'Johan',
      entitled: 'Enfant',
      sex: 'femme',
      birthDay: '01/01/2000',
    },
    {
      id: 5,
      lastName: 'Zoli',
      firstName: 'Johan',
      entitled: 'Enfant',
      sex: 'femme',
      birthDay: '01/01/2000',
    },
    {
      id: 6,
      lastName: 'Zoli',
      firstName: 'Johan',
      entitled: 'Enfant',
      sex: 'femme',
      birthDay: '01/01/2000',
    },
  ]

  console.log('beneficiaryMockUp:', beneficiaryMockUp)

  return (
    <div>
      <AccordionDetails>
        <Divider variant="middle" sx={{ marginBottom: 3 }}>
          <Chip
            label="Assurés"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectInput
              options={prefix}
              name="Prefix"
              control={control}
              readOnly={!updatable}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="NameOfTheInsured"
              name="NameOfTheInsured"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="FirstName"
              name="FirstName"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="S.N.S.S.Insured"
              name="S.N.S.S.Insured"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="BirthName"
              name="BirthName"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <DateInput name="DateOfBirth" control={control} />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="Insured'SAddress"
              name="Insured'SAddress"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="Insured'SCity"
              name="Insured'SCity"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="Insured'SPostalCode"
              name="Insured'SPostalCode"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="CountryOfTheInsured"
              name="CountryOfTheInsured"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.dividerNotTop}>
          <Chip
            label="Médecins"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="ReferringPhysician"
              name="ReferringPhysician"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="Prescriber"
              name="Prescriber"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue=""
              placeholder="AttendingPhysician"
              name="AttendingPhysician"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
        </Grid>

        <Divider variant="middle" className={classes.dividerNotTop}>
          <Chip
            label="Infos carte"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>

        <Grid
          container
          spacing={2}
          style={{
            background: '#e2e6f2',
            borderRadius: '20px',
            margin: '25px 0px',
            padding: '0px 10px 15px 0px',
            width: '100%',
          }}
          alignItems="center"
        >
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'SocialSecurityNumber' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Plan' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Fund/Center' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'ManagementCode' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'AmoRights' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Coverages' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Beneficiary' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
        </Grid>
        <Grid container style={{ minHeight: '20vh', marginTop: 25 }}>
          <Paper
            elevation={1}
            style={{
              width: '100%',
              // border: '1px solid rgba(0, 0, 0, 0.125)',
              backgroundColor: '#e2e6f2',
              borderRadius: '15px',
            }}
          >
            <SimpleTable
              rows={beneficiaryMockUp}
              columns={columns}
              loading={false}
              simpleToolBarProps={{
                title: ' ',
                icon: '',
              }}
              // sx={{ backGround: 'red' }}
            />
          </Paper>
        </Grid>

        <Divider variant="middle" className={classes.dividerNotTop}>
          <Chip
            label="Infos web services"
            variant="outlined"
            className={classes.chipDivider}
          />
        </Divider>

        <Grid
          container
          spacing={2}
          style={{
            background: '#e2e6f2',
            borderRadius: '20px',
            margin: '25px 0px',
            padding: '0px 10px 15px 0px',
            width: '100%',
          }}
          alignItems="center"
        >
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'SocialSecurityNumber' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Plan' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Fund/Center' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'ManagementCode' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'AmoRights' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Coverages' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Beneficiary' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'AmcRights' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'Coverages' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Text
              text={`${intl.formatMessage({ id: 'MutualInsurance' })} :`}
              className={classes.textName}
            />
            <Text
              text={`${'answer'}`}
              format={true}
              className={classes.textAnswer}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  )
}
