import { AccordionDetails, Divider, Grid } from '@mui/material'

import {
  DateInput,
  RadioInput,
  TextInput,
  SelectInput,
} from '../../../../components/inputs'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientGeneralnfoSchema } from '@utils/schemas'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import countries from './nationality.json'

type patientInformationProps = {
  updatable: boolean
}

type Values = {
  gender: string
  lastName: string
  lastNameBirth: string
  firstName: string
  birthday: Date
}

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(patientGeneralnfoSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} />
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioInput
              row={true}
              control={control}
              input="gender"
              label={intl.formatMessage({ id: 'gender' })}
              options={[
                { label: intl.formatMessage({ id: 'male' }), value: 'male' },
                {
                  label: intl.formatMessage({ id: 'female' }),
                  value: 'female',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              defaultValue="Zoli"
              placeholder="lastName"
              name="lastName"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          {
            <Grid item xs={12} lg={4}>
              <TextInput
                defaultValue="Zoli"
                placeholder="birthName"
                name="birthName"
                size="medium"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly={!updatable}
                fullWidth
              />
            </Grid>
          }
          <Grid item xs={12} lg={4}>
            <TextInput
              defaultValue="Johan"
              name="firstName"
              placeholder="firstName"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <DateInput
              name="birthDate"
              control={control}
              errors={errors}
              readOnly
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue="Paris"
              name="cityBirth"
              placeholder="cityBirth"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              defaultValue="75001"
              name="cityCodeBirth"
              placeholder="cityCodeBirth"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              options={countries}
              name="nationality"
              control={control}
              readOnly={!updatable}
            />
          </Grid>
          <Divider variant="fullWidth" sx={{ marginBottom: 3, marginTop: 3 }} />
          <Grid item xs={2}>
            <TextInput
              defaultValue="01 01 01 01 01"
              name="phone1"
              placeholder="phone1"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextInput
              defaultValue="01 01 01 01 01"
              name="phone2"
              placeholder="phone2"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextInput
              defaultValue="monAdresseMail@monMail.com"
              name="email"
              placeholder="phonemaile2"
              size="medium"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly={!updatable}
              fullWidth
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  )
}
