import DialogTitle from '@components/dialogs/DialogTitle'
import { DialogContent } from '@material-ui/core'
import {
  Box,
  Icon,
  Tab,
  Tabs,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import Switch from '@mui/material/Switch'
import Dialog from '@mui/material/Dialog'
import theme from '@utils/theme'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import TabWorkList from './TabWorkList'
// import PatientInformation from './DialogPatientInfos'
import PatientInformation from './DialogPatientInfosGrid'
import PatientRights from './DialogPatientRights'
import PatientValidation from './DialogPatientValidation'
import PatientExam from './DialogPatientExam'
import PatientPortail from './DialogPatientPortail'
import { UserInfosCard } from '@components/cards'
import FormPatientCard from './Form/FormPatientCard'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export interface PatientDialogProps {
  patientId: number
  open: boolean
  setOpen: (open: boolean) => void
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box>
      {value === index && <Box sx={{ paddingTop: '3px' }}>{children}</Box>}
    </Box>
  )
}

export default function PatientDialog({
  patientId,
  open,
  setOpen,
}: PatientDialogProps) {
  const [value, setValue] = useState(0)
  const [updatable, setUpdatable] = useState(false)

  const intl = useIntl()

  const onClose = () => {
    setOpen(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatable(event.target.checked)
  }

  const myTabsProps = [
    {
      labelTab: 'patientInformation',
      icon: 'fas fa-user-plus',
      title: '123',
      component: <PatientInformation updatable={updatable} />,
    },
    {
      labelTab: 'patientRights',
      icon: 'fas fa-user-check',
      title: '123',
      component: <PatientRights updatable={updatable} />,
    },
    {
      labelTab: 'patientValidation',
      icon: 'fas fa-user-tag',
      title: '123',
      component: <PatientValidation updatable={updatable} />,
    },
    {
      labelTab: 'patientExam',
      icon: 'fas fa-user-minus',
      title: '123',
      component: <PatientExam updatable={updatable} />,
    },
    {
      labelTab: 'portail',
      icon: 'fas fa-file-invoice-dollar',
      title: '123',
      component: <PatientPortail />,
    },
  ]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 20,
          minWidth: '50vw',
          maxWidth: '80vw',
          background: '#E2E6F2',
          width: '65%',
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title="Fiche Patient"
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 30,
          paddingTop: 10,
          overflow: 'hidden',
          height: '80vh',
        }}
      >
        {/* //début du drawer */}
        <Box>
          <FormPatientCard />
        </Box>
        <Box
          sx={{
            marginTop: '5px',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
              // backgroundColor: '#fff',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={handleChange}
              aria-label="exams-tabs"
            >
              {myTabsProps.map((el, index) => {
                return (
                  <Tab
                    label={intl.formatMessage({ id: el.labelTab })}
                    {...a11yProps(0)}
                    sx={{
                      borderRadius: '5px 5px 0px 0px',
                      marginRight: '5px',
                      backgroundColor: '#fff',
                    }}
                    key={index}
                  />
                )
              })}
            </Tabs>

            <FormControlLabel
              control={
                <Switch
                  checked={updatable}
                  onChange={handleChangeSwitch}
                  color="secondary"
                />
              }
              label="Modification"
            />
          </Box>

          <div
            style={{
              overflow: 'auto',
              maxHeight: '65vh',
            }}
          >
            {myTabsProps.map((el, index) => {
              return (
                <TabPanel value={value} index={index} key={index}>
                  {el.component}
                </TabPanel>
              )
            })}
          </div>
        </Box>
        {/* //fin du drawer */}
      </DialogContent>
    </Dialog>
  )
}
