import { Grid, Icon, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useIntl } from 'react-intl'
import theme from '@utils/theme'

export default function FormPatientCard() {
  const intl = useIntl()
  return (
    <Grid
      container
      spacing={0}
      style={{
        background: '#fff',
        borderRadius: '20px',
        margin: '25px 0px',
        // width: '85vh',
      }}
      alignItems="center"
    >
      <Grid item xs={12 / 5}>
        <ListItem>
          <ListItemIcon>
            <Icon
              className="fas fa-user"
              style={{ color: theme.palette.primary.main, width: '100%' }}
            />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: 'patient' })}
            secondary={'Johan ZOLI'}
            secondaryTypographyProps={{
              style: { color: 'black', textTransform: 'capitalize' },
            }}
          />
        </ListItem>
      </Grid>
      <Grid item xs={12 / 5}>
        <ListItem>
          <ListItemIcon>
            <Icon
              className="fas fa-user-tag"
              style={{ color: theme.palette.primary.main, width: '100%' }}
            />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: 'pid' })}
            secondary={'B36735'}
            secondaryTypographyProps={{
              style: { color: 'black', textTransform: 'capitalize' },
            }}
          />
        </ListItem>
      </Grid>
      <Grid item xs={12 / 5}>
        <ListItem>
          <ListItemIcon>
            <Icon
              className="fas fa-phone-alt"
              style={{ color: theme.palette.primary.main, width: '100%' }}
            />
          </ListItemIcon>

          <ListItemText
            primary={intl.formatMessage({ id: 'phoneNumber' })}
            secondary={'01 02 03 04 05'}
            secondaryTypographyProps={{
              style: { color: 'black', textTransform: 'capitalize' },
            }}
          />
        </ListItem>
      </Grid>
      <Grid item xs={12 / 5}>
        <ListItem>
          <ListItemIcon>
            <Icon
              className="fas fa-id-card"
              style={{
                color: theme.palette.primary.main,
                width: '100%',
              }}
            />
          </ListItemIcon>

          <ListItemText
            primary={intl.formatMessage({ id: 'ageRange' })}
            secondary={'45 ans'}
            secondaryTypographyProps={{
              style: { color: 'black', textTransform: 'capitalize' },
            }}
          />
        </ListItem>
      </Grid>
      <Grid item xs={12 / 5}>
        <ListItem>
          <ListItemIcon>
            <Icon
              className="fas fa-stethoscope"
              style={{ color: theme.palette.primary.main, width: '100%' }}
            />
          </ListItemIcon>

          <ListItemText
            primary={intl.formatMessage({ id: 'attendingDoctor' })}
            secondary={'Dr Jean DUPONT'}
            secondaryTypographyProps={{
              style: { color: 'black', textTransform: 'capitalize' },
            }}
          />
        </ListItem>
      </Grid>
    </Grid>
  )
}
