import { Button } from '@components/buttons'
import { Text } from '@components/texts'
import { DialogTitle } from '@components/titles'
import { DataTable } from '@components/tables'
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import 'moment/locale/fr'
import * as React from 'react'
import { useCallback, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import FundView from './FundView'
import MutuelleView from './MutuelleView'
import CashRegisterView from './CashRegisterView'
import CashRegisterMovementView from './CashRegisterMovementView'
import PayementModeView from './PaymentModeView'
import BackView from './BankView'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}
  

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
  
const useStyles = makeStyles(() => ({
  action: {
    cursor: 'pointer',
    padding: 6,
    margin: '2px 0px',
    '&:hover': {
      backgroundColor: 'rgb(236,240,251)',
    },
  },
}))

export interface DialogProps {
  openPatientDetails: boolean
}

export default function TabBilling (props) {
  const dispatch = useDispatch()
  const [test, setTest] = useState(false)
  const [value, setValue] = React.useState(1)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const handleClickActionUpdateMO = () => {
    setTest(true)
  }

  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 0px' }}>
      <Grid container spacing={4} style={{ height: '96vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{
              width: '100%',
              borderRadius: '0px 25px 25px 25px',
            }}
          >
            <Grid container sx={{height: '100%', width: '100%', }}>
              <Grid item sm={1} >
                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs"
                  sx={{ borderRight: 1, borderColor: 'divider', height: '100%'}}
                >
                  <Tab label="Organismes" disabled sx={{ '&.Mui-disabled': { color: 'black', fontSize: 16, opacity: 1 }}}   {...a11yProps(0)} />
                  <Tab label="Caisses" {...a11yProps(1)} />
                  <Tab label="Mutuelles" {...a11yProps(2)} />
                  <Tab label="Sociètés" {...a11yProps(3)} />
                  <Tab label="Facturation" disabled sx={{ '&.Mui-disabled': { color: 'black', fontSize: 16, opacity: 1 }}} {...a11yProps(4)} />
                  <Tab label="Caisses enregistreuses" {...a11yProps(5)} />
                  <Tab label="Motifs des mouvements de caisses" {...a11yProps(6)} />
                  <Tab label="Banques" {...a11yProps(7)} />
                  <Tab label="Mode de réglements" {...a11yProps(8)} />
                </Tabs>
              </Grid>
              <Grid item sm={11}>
                <TabPanel value={value} index={1}>
                  <FundView/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <MutuelleView/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <>
                    company page / site form
                  </>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <CashRegisterView/>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <CashRegisterMovementView/>
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <BackView/>
                </TabPanel>
                <TabPanel value={value} index={8}>
                  <PayementModeView/>
                </TabPanel>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
